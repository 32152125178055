<template>
  <div class="orders-table-container">
    <OrdersTable
      v-if="orders && orders.length > 0"
      :orders="orders"
      @delete="deleteOrder"
      @addAllToCart="addAllToCart"
      @editOrder="editOrder"
    ></OrdersTable>
    <v-pagination
      v-if="orderPager && orderPager.totPages > 1"
      :value="pageFilter"
      :page="orderPager.selPage"
      :length="orderPager.totPages ? orderPager.totPages : 0"
      :totalVisible="7"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
      class="py-6"
    ></v-pagination>

    <div v-if="orders && orders.length == 0">
      {{ $t("orders.noOrders") }}
    </div>
  </div>
</template>
<script>
import OrdersTable from "@/components/orders/OrdersTable.vue";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import categoryMixin from "~/mixins/category";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrdersTableContainer",
  mixins: [categoryMixin],
  components: {
    OrdersTable
  },
  props: {
    customPageSize: { type: Number, required: false }
  },
  data() {
    return {
      orders: {},
      orderPager: {}
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    reload() {
      OrderService.getOrders(
        this.pageFilter,
        this.customPageSize || process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
        false,
        true,
        true,
        false
      ).then(data => {
        this.orders = data.orders;
        this.orderPager = data.page;
      });
    },
    async deleteOrder(orderId) {
      await OrderService.deleteOrder(orderId);
      this.reload();
      let order = await OrderService.getOrder(orderId);
      AnalyticsService.refund(order);
    },
    async editOrder(orderId) {
      try {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.confirmOrderEdit")
        });
        if (res) {
          OrderService.editOrder(orderId).then(() => {
            this.loadCart();

            this.$router.push({
              name: "Cart"
            });
          });
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorOnOrderEditConfirm")
        });
        return null;
      }
    },
    async addAllToCart(orderId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllFromOrder")
      });
      if (res) {
        await this.addProductsFromOrder(orderId);
      }
    },
    handlePageFilter(page) {
      this.$store.dispatch("category/setFilterPage", page);
      this.reload();
      if (!this.customPageSize) this.$vuetify.goTo(0);
    },
    updateOrdersFilter() {
      this.reload();
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
