<template>
  <div class="generic-list orders-table-container">
    <v-row
      no-gutters
      class="row-header text-wdy-2 py-5 grey lighten-2 default--text rounded-sm"
    >
      <v-col cols="0" sm="0" md="1" lg="1" class="hidden-sm-and-down">
        <strong>{{ $t("orders.header.orderNumber") }}</strong>
      </v-col>
      <v-col cols="5" sm="4" md="2" lg="2">
        <strong>{{ $t("orders.header.date") }}</strong>
      </v-col>
      <v-col cols="4" sm="3" md="2" lg="2" class="">
        <strong>{{ $t("orders.header.status") }}</strong>
      </v-col>
      <v-col cols="0" sm="0" md="3" lg="3" class="hidden-sm-and-down">
        <strong>{{ $t("orders.header.type") }}</strong>
      </v-col>
      <v-col cols="3" sm="2" md="1" lg="1">
        <strong>{{ $t("orders.header.total") }}</strong>
      </v-col>
      <v-col cols="0" sm="0" md="2" lg="2" class="hidden-sm-and-down">
        <strong>{{ $t("orders.header.paymentType") }}</strong>
      </v-col>
      <v-col cols="0" sm="0" md="1" lg="1"></v-col>
    </v-row>

    <v-list class="mt-4 orders-list">
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        v-bind:to="'/profile/orders/' + order.orderId"
        :class="{ active: order.orderStatusId === 3 }"
      >
        <v-row
          no-gutters
          align="center"
          class="flex-sm-nowrap text-caption text-sm-body-2"
        >
          <!-- order number -->
          <v-col cols="0" sm="0" md="1" lg="1" class="hidden-sm-and-down">
            <div class="font-weight-bold primary--text">
              {{ order.orderId }}
            </div>
            <div class="eugenio-img accent pa-1" v-if="order.orderTypeId == 4">
              <v-img contain src="/img_layout/services_icons/eugenio.svg" />
            </div>
          </v-col>
          <!-- data -->
          <v-col cols="5" sm="4" md="2" lg="2">
            <div class="font-weight-bold">
              {{
                $dayjs(
                  order.timeslot.date + " " + order.timeslot.beginTime,
                  "YYYY-MM-DD hh:mm:ss A"
                ).format("D MMMM YYYY")
              }}
            </div>
          </v-col>
          <!-- stato -->
          <v-col cols="4" sm="3" md="2" lg="2">
            <OrderStatusBadge v-bind:order="order" />
          </v-col>
          <!-- order type -->
          <v-col cols="0" sm="0" md="3" lg="3" class="hidden-sm-and-down">
            <div class="d-flex align-center default--text font-weight-bold">
              <img
                aria-hidden
                :src="
                  $t(
                    'navbar.service.' +
                      order.shippingAddress.deliveryServiceId +
                      '.icon'
                  )
                "
                height="48"
                width="48"
                class="mr-2"
              />
              <span class="pr-3 text-capitalize"
                >{{
                  $t(
                    "navbar.address." +
                      order.shippingAddress.addressTypeId +
                      ".format",
                    order.shippingAddress
                  )
                }}
              </span>
            </div>
          </v-col>
          <!-- totale -->
          <v-col cols="3" sm="2" md="1" lg="1">
            <strong>{{ $n(order.grossTotal, "currency") }}</strong>
          </v-col>
          <!-- pagamento -->
          <v-col
            cols="0"
            sm="3"
            md="2"
            lg="2"
            class="mt-4 mt-sm-0 hidden-sm-and-down"
          >
            {{ $t("paymentType." + order.paymentTypeId) }}
          </v-col>
          <!-- buttons -->
          <v-col
            cols="0"
            sm="0"
            md="1"
            lg="1"
            class="orders-list-action-container d-flex justify-space-between justify-sm-end mt-6 mt-sm-0 hidden-sm-and-down"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="primary"
                  height="40"
                  v-if="order.isPayable"
                  v-bind:to="'/profile/orders/' + order.orderId"
                >
                  <v-icon color="white">$creditcard</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("orders.buttons.retryPayment") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="col-auto"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent="editOrder(order.orderId)"
                  depressed
                  small
                  color="primary"
                  height="40"
                  v-if="order.isEditable"
                >
                  <v-icon>$edit</v-icon>
                  <span class="d-sm-none ml-1">{{
                    $t("orders.buttons.modifyOrder")
                  }}</span>
                </v-btn>
              </template>
              <span>{{ $t("orders.buttons.modifyOrder") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="primary"
                  height="40"
                  class="col-auto ml-sm-4"
                  @click.stop.prevent="deleteOrder(order.orderId)"
                  v-if="order.isDeletable"
                >
                  <v-icon>$delete</v-icon>
                  <span class="d-sm-none ml-1">{{
                    $t("orders.buttons.cancelOrder")
                  }}</span>
                </v-btn>
              </template>
              <span>{{ $t("orders.buttons.cancelOrder") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="col-12"
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="primary"
                  height="40"
                  v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
                  @click.stop.prevent="addAllToCart(order.orderId)"
                >
                  <v-icon>$cart</v-icon>
                  <span class="d-sm-none ml-1">{{
                    $t("orders.buttons.reorderOrder")
                  }}</span>
                </v-btn>
              </template>
              <span>{{ $t("orders.button.reorder") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
@import "@/scss/_mixins.scss";
.orders-table-container {
  .active {
    border: 2px solid var(--v-primary-lighten2) !important;
  }
  .small {
    font-size: 10px;
    min-height: 16px;
    text-transform: uppercase;
  }
  .v-list.orders-list {
    .v-list-item {
      background-color: var(--v-green-lighten2);
      &:nth-child(odd) {
        background: white;
      }
      button.v-btn {
        //width: 100%;
        @media (min-width: 768px) {
          width: fit-content;
        }
      }
    }
  }
  .orders-list-action-container {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      flex-wrap: wrap;
      button {
        width: 100%;
        &:nth-of-type(2) {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";

import cartInfo from "~/mixins/cartInfo";

import { mapState, mapGetters } from "vuex";

export default {
  name: "OrdersTable",
  props: {
    orders: {
      type: Array
    }
  },
  mixins: [cartInfo],
  components: {
    OrderStatusBadge
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    editOrder(orderId) {
      this.$emit("editOrder", orderId);
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", orderId);
    }
  },
  mounted() {}
};
</script>
